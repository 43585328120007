import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import user from "./reducers/userdata_reducer";
// import storage from "redux-persist/lib/storage";
import storage from "./storage";
import { persistReducer, persistStore } from "redux-persist";

import {
  AllUserDataReducer,
  AllCouchDataReducer,
  AllAdminDataReducer,
  AllPackageReducer,
  AllInvoiceReducer,
  AllPlanReducer,
  AllChatUser,
  AllRedeemRequest,
  AllRedeemOfferRequest,
  AssignOfferRequest,
  setCurrecyValueReducer,
} from "./reducers/all_user_data_reducer";

const rootRaducer = combineReducers({
  user,
  AllUserDataReducer,
  AllCouchDataReducer,
  AllAdminDataReducer,
  AllPackageReducer,
  AllPlanReducer,
  AllInvoiceReducer,
  AllChatUser,
  AllRedeemRequest,
  AllRedeemOfferRequest,
  AssignOfferRequest,
  setCurrecyValueReducer,
});

const persistConfig = {
  key: "raad-dojo",
  storage,
  timeout: undefined,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootRaducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);
