export const setUserData ="setall_data";
export const set_All_User_Data ="set_all_user_data";
export const set_All_Couch_Data ="set_all_couch_data";
export const set_All_Admin_Data ="set_all_admin_data";
export const set_All_package_Data="set_all_package_data";
export const set_All_plan_Data="set_all_plan_data";
export const set_All_invoice_Data="set_all_invoice_data";
export const set_All_Chat_user_Data="set_all_chat_user_data";
export const set_All_RedeemRequest="set_all_Redeem_Request";
export const set_All_RedeemOfferRequest="set_all_Redeem_offer_Request";
export const set_All_AssignOfferRequest="set_all_Assign_offer";
export const SET_CURRENCY_VALUE_OF_USD="set_currency_value";





