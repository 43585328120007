import {
  setUserData,
} from "@/constants/redux_constants";

const initialState = {
  user: {},
  token: "",
  isLoggedIn: false,
};

export default function todosReduser(state = initialState, action) {
  switch (action.type) {
    case setUserData:
      return action.payload;
    default:
      return state;
  }
}
