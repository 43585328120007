"use client";
import { Provider } from "react-redux";
import { store, persistor } from "@/store";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import i18n from "i18next";
import { GoogleTagManager } from "@next/third-parties/google";

import ScrollToTop from "react-scroll-to-top";
import InitI18n from "@/components/tools/InitI18n";
import ServiceWorker from "@/components/tools/ServiceWorker";
import JsonLd from "@/components/tools/JsonLd";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function RootLayout({ children }) {
  const theme = createTheme({
    palette: {
      dashboard: {
        main: "#f46e6e",
      },
    },
  });
  return (
    <html lang={i18n.language}>
      <head>
        <InitI18n />
        <ServiceWorker />
        <GoogleTagManager gtmId="GTM-TKKCPDH3" />
        <JsonLd />
        <link rel="stylesheet" href="/assets/css/nav-style.css" />
        <link rel="manifest" href="/manifest.json" />
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.png" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="theme-color" content="#000000" />
        <link rel="stylesheet" type="text/css" href="/assets/css/custom.css" />
        {/* <link
          href="/new_assets/vendors/stroke-icon/style.css"
          rel="stylesheet"
        /> */}
        <link
          href="/new_assets/vendors/flat-icon/flaticon.css"
          rel="stylesheet"
        />
        <link href="/new_assets/css/bootstrap.min.css" rel="stylesheet" />
        <link
          href="/new_assets/vendors/revolution/css/settings.css"
          rel="stylesheet"
        />
        <link
          href="/new_assets/vendors/revolution/css/layers.css"
          rel="stylesheet"
        />
        <link
          href="/new_assets/vendors/revolution/css/navigation.css"
          rel="stylesheet"
        />
        <link
          href="/new_assets/vendors/animate-css/animate.css"
          rel="stylesheet"
        />
        <link
          href="/new_assets/vendors/magnify-popup/magnific-popup.css"
          rel="stylesheet"
        />
        <link
          href="/new_assets/vendors/owl-carousel/owl.carousel.min.css"
          rel="stylesheet"
        />
        <link href="/new_assets/App.css" rel="stylesheet" />
        <link href="/new_assets/index.css" rel="stylesheet" />
        <link href="/new_assets/css/style.css?v=1.5" rel="stylesheet" />
        <link href="/new_assets/css/custom.css" rel="stylesheet" />
        <link href="/new_assets/css/ck.css" rel="stylesheet" />
        <link
          href="/new_assets/css/responsive.css?v=1.2"
          rel="stylesheet"></link>
        <link
          href="https://fonts.googleapis.com/css?family=Nunito:400,700"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="/assets/css/intl-tel-input.css" />
        <link href="/new_assets/css/font-awesome.min.css" rel="stylesheet" />
      </head>
      <body>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ScrollToTop
              smooth
              component={
                <i style={{ color: "#555" }} className="fa fa-arrow-up" />
              }
            />
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </PersistGate>{" "}
        </Provider>

        <script
          src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
          integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
          crossOrigin="anonymous"
        />
        <script src="/new_assets/vendors/lightbox/simpleLightbox.min.js" />
        <script src="/new_assets/vendors/owl-carousel/owl.carousel.min.js" />
        <script src="https://cdn.tutorialjinni.com/intl-tel-input/17.0.8/js/intlTelInput.min.js" />
        <script src="/new_assets/vendors/magnify-popup/jquery.magnific-popup.min.js" />
        <script
          src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js"
          integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
          crossOrigin="anonymous"
          // rel="preconnect"
        />
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js"
          integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
          crossOrigin="anonymous"
          // rel="preconnect"
        />
        <script src="/new_assets/vendors/revolution/js/jquery.themepunch.tools.min.js" />
        <script src="/new_assets/vendors/revolution/js/jquery.themepunch.revolution.min.js" />
        <script src="/new_assets/vendors/revolution/js/extensions/revolution.extension.actions.min.js" />
        <script src="/new_assets/vendors/revolution/js/extensions/revolution.extension.video.min.js" />
        <script src="/new_assets/vendors/revolution/js/extensions/revolution.extension.slideanims.min.js" />
        <script src="/new_assets/vendors/revolution/js/extensions/revolution.extension.layeranimation.min.js" />
        <script src="/new_assets/vendors/revolution/js/extensions/revolution.extension.navigation.min.js" />
        <script src="/new_assets/vendors/counterup/jquery.waypoints.min.js" />
        <script src="/new_assets/vendors/counterup/jquery.counterup.min.js" />
        <script src="/new_assets/js/contact.js" />
        <script src="/new_assets/js/jquery.validate.min.js" />
      </body>
    </html>
  );
}
