import {
  set_All_User_Data,
  set_All_Couch_Data,
  set_All_Admin_Data,
  set_All_package_Data,
  set_All_plan_Data,
  set_All_invoice_Data,
  set_All_Chat_user_Data,
  set_All_RedeemRequest,
  set_All_RedeemOfferRequest,
  set_All_AssignOfferRequest,
  SET_CURRENCY_VALUE_OF_USD,
} from "@/constants/redux_constants";

export function AllUserDataReducer(state = [], action) {
  switch (action.type) {
    case set_All_User_Data:
      return action.payload;
    default:
      return state;
  }
}

export function AllCouchDataReducer(state = [], action) {
  switch (action.type) {
    case set_All_Couch_Data:
      return action.payload;
    default:
      return state;
  }
}

export function AllAdminDataReducer(state = [], action) {
  switch (action.type) {
    case set_All_Admin_Data:
      return action.payload;
    default:
      return state;
  }
}

export function AllPackageReducer(state = [], action) {
  switch (action.type) {
    case set_All_package_Data:
      return action.payload;
    default:
      return state;
  }
}

export function AllPlanReducer(state = [], action) {
  switch (action.type) {
    case set_All_plan_Data:
      return action.payload;
    default:
      return state;
  }
}

export function AllInvoiceReducer(state = [], action) {
  switch (action.type) {
    case set_All_invoice_Data:
      return action.payload;
    default:
      return state;
  }
}

export function AllChatUser(state = [], action) {
  switch (action.type) {
    case set_All_Chat_user_Data:
      return action.payload;
    default:
      return state;
  }
}

export function AllRedeemRequest(state = [], action) {
  switch (action.type) {
    case set_All_RedeemRequest:
      return action.payload;
    default:
      return state;
  }
}

export function AllRedeemOfferRequest(state = [], action) {
  switch (action.type) {
    case set_All_RedeemOfferRequest:
      return action.payload;
    default:
      return state;
  }
}

export function AssignOfferRequest(state = [], action) {
  switch (action.type) {
    case set_All_AssignOfferRequest:
      return action.payload;
    default:
      return state;
  }
}

export function setCurrecyValueReducer(
  state = { currency: "USD", val: 1 },
  action
) {
  switch (action.type) {
    case SET_CURRENCY_VALUE_OF_USD:
      return action.currency;
    default:
      return state;
  }
}
