"use client"
import React, { useEffect } from 'react'

const JsonLd = () => {
      const jsonLd = {
        "@context": "https://schema.org/",
        "@type": "Person",
        name: "Captain Raad",
        url: "https://raaddojo.com/",
        image: "https://raaddojo.com/image/logo.png",
        sameAs: [
          "https://www.instagram.com/raad_dojo",
          "https://www.youtube.com/@raaddojo",
        ],
        jobTitle: "Men and women coaches",
        worksFor: {
          "@type": "Organization",
          name: "Raad Dojo",
        },
      };
      useEffect(() => {
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.innerHTML = JSON.stringify(jsonLd);
        document.head.appendChild(script);

        return () => {
          document.head.removeChild(script);
        };
      }, []);
  return (
    <></>
  )
}

export default JsonLd