"use client";
import { toast } from "react-toastify";
import { useEffect } from "react";

const ServiceWorker = () => {
  useEffect(() => {
    if (
      "serviceWorker" in navigator &&
      process.env.NODE_ENV !== "development"
    ) {
      navigator.serviceWorker
        .register("/service-worker.js", {
          scope: "/",
          updateViaCache: "none",
        })
        .then((registration) => {
          let svcworker =
            registration.installing ||
            registration.waiting ||
            registration.active;
          navigator.serviceWorker.addEventListener("controllerchange", () => {
            if (svcworker) {
              svcworker.postMessage({ type: "SKIP_WAITING" });
            }
            window.location.reload();
          });
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
    let isOnline = "onLine" in navigator ? navigator.onLine : true;
    let offlineToastId;
    if (!isOnline) {
      offlineToastId = toast.error("No internet connection", {
        position: "top",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
    window.addEventListener("online", () => {
      offlineToastId && toast.dismiss(offlineToastId);
      offlineToastId = toast.success("Back to online.", {
        position: "top-center",
      });
    });
    window.addEventListener("offline", () => {
      offlineToastId && toast.dismiss(offlineToastId);
      offlineToastId = toast.warning("No internet connection", {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: " bg-gradient w-full text-center",
      });
    });
  }, []);
  return <></>;
};

export default ServiceWorker;
